import { useContext } from 'react';
import { MainContext } from '../App.js';

const ThemeIcon = () => {

  const { theme, setTheme } = useContext(MainContext);

  const containerStyle = "flex flex-row justify-end items-center";
  const svgStyle = "hidden w-8 h-8 fill-neutral-100 hover:fill-neutral-400 transition-all cursor-pointer";

  return (
    <div className={containerStyle}>
      <svg className={svgStyle} onClick={() => setTheme(!theme)} viewBox="0 0 24 24">
        <path d="M20.742 13.045a8.088 8.088 0 0 1-2.077.271c-2.135 0-4.14-.83-5.646-2.336a8.025 8.025 0 0 1-2.064-7.723A1 1 0 0 0 9.73 2.034a10.014 10.014 0 0 0-4.489 2.582c-3.898 3.898-3.898 10.243 0 14.143a9.937 9.937 0 0 0 7.072 2.93a9.93 9.93 0 0 0 7.07-2.929a10.007 10.007 0 0 0 2.583-4.491a1.001 1.001 0 0 0-1.224-1.224zm-2.772 4.301a7.947 7.947 0 0 1-5.656 2.343a7.953 7.953 0 0 1-5.658-2.344c-3.118-3.119-3.118-8.195 0-11.314a7.923 7.923 0 0 1 2.06-1.483a10.027 10.027 0 0 0 2.89 7.848a9.972 9.972 0 0 0 7.848 2.891a8.036 8.036 0 0 1-1.484 2.059z"/>
      </svg>
    </div>
  )
}

export default ThemeIcon;
